import React, {useState, useRef, useEffect} from 'react';
import InstallPWA from "./components/InstallPWA";
import flashlightHandler from "./classes/torchClass";
import './App.css';
import i18n from './i18n';
import { withNamespaces } from 'react-i18next';
import enPic from './asets/flags/en.png';
import skPic from './asets/flags/sk.png';
import czPic from './asets/flags/cz.png';
import dePic from './asets/flags/de.png';
import frPic from './asets/flags/fr.png';

import home from './asets/home1.png';
import toron from './asets/toron.png';
import toroff from './asets/toroff.png';

// import { Helmet, HelmetProvider } from 'react-helmet-async';

// router
import {
  // BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
  // useNavigate,
  Link
} from "react-router-dom";
//routes zoznam
import Main from "./routes/Main";
import About from "./routes/About";
import Reader from "./routes/Reader";
import Generate from "./routes/Generate";
import Archive from "./routes/Archive";

const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);    
} 

 //funkcia po zavolani zmeni jazyk aplikacie
 function transl(llang) {
  if (llang==='sk') {
    changeLanguage('sk');
  } else if (llang==='de') {
    changeLanguage('de');
  } else if (llang==='fr') {
    changeLanguage('fr');
  } else if (llang==='cz') {
    changeLanguage('cz');
  } else {
    changeLanguage('en');
  }
}


//komponenta ktora urobi route forwarding po naloadovani stranky podla window location url
//import RefreshToRoute from "./components/RefreshToRoute";
// let language='';
let torch=false;
// let torchFirst=true;

function App({t}) {
  const [imgSrc, setImgSrc] = useState(toroff);  
  const skref = useRef();
  const deref = useRef();
  const frref = useRef();
  const czref = useRef();
  const enref = useRef();
  //searchParams.get("lang");
  //const [lang,setLang]=useState('');

  // language=i18n.language;
  
  useEffect(() => {
    // call api or anything
    transl(window.lang);
 },[]);



  function toggleTorch() {
    try {
      flashlightHandler.accessFlashlight(t('cameraErr'));
      torch=!torch;
      flashlightHandler.setFlashlightStatus(torch);
      setImgSrc(prevSrc => (prevSrc === toroff ? toron : toroff));
    } catch(e) {
      return;
    }
  }

  return (
    <div className="App">
      {/* <HelmetProvider> */}
        {/* <Helmet>‍
          <title>{t('seo_title')}</title>‍
          <meta name="description" content={t('seo_description')} />
        </Helmet> */}
        <HashRouter>
          {/* horna lista */}
          <div style={{"width":"100%","height":"35px","padding":"5px","position":"relative","marginBottom":"10px"}}>
            <Link to='/'><img alt="home" style={{position:"absolute","left":"0px","cursor":"pointer"}} width="40" title={t('homeBtn')} src={home} /></Link>
            <InstallPWA />
            <img alt="tork" onClick={()=>{toggleTorch()}} style={{position:"absolute","top":"2px","left":"110px","cursor":"pointer"}} height="40" width="35" src={imgSrc} />
            {/* jazyky */}
            <div style={{position:"absolute","right":"0px","cursor":"pointer"}}>
              <img src={enPic} ref={enref} alt="English" onClick={()=>changeLanguage('en')} style={{padding:"2px"}}/>
              <img src={dePic} ref={deref} alt="Deutsch" onClick={()=>changeLanguage('de')} style={{padding:"2px"}}/>
              <img src={frPic} ref={frref} alt="Français" onClick={()=>changeLanguage('fr')} style={{padding:"2px"}}/>              
              <img src={czPic} ref={czref} alt="Česky" onClick={()=>changeLanguage('cz')} style={{padding:"2px"}}/>
              <img src={skPic} ref={skref} alt="Slovensky" onClick={()=>changeLanguage('sk')} style={{padding:"2px"}}/>              
              <br />
            </div>
          </div>

          {/* {t('Welcome to React')}{t('zzz')} */}
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/about" element={<About />} />
            <Route path="/reader" element={<Reader />} />
            <Route path="/generate" element={<Generate />} />
            <Route path="/archive" element={<Archive />} />

            {/* <button onClick={()=>navigate('/about')}>zzz</button> */}
          </Routes>

          <br style={{clear:"both"}}/> 
          
          
    {/* <button class="okbtn" @click="goToGenerator()"  title="QR CODE generátor online">Generátor QR kódu</button><br><br><br>
    <router-link to="/about"><span style="font-size:19px">Ako používať túto aplikáciu?</span></router-link> */}


        </HashRouter>
      {/* </HelmetProvider> */}
      <br />
      <center>
        <a href="https://qrcodeonline.rezz.eu" title='QR code, online reader, and QR code generator for free' alt='QR code, online reader, and QR code generator for free'>English</a> -
        <a href="https://qrcodelesegerat.rezz.eu" title='QR-Code, Online-Lesegerät und QR-Code-Generator kostenlos' alt='QR-Code, Online-Lesegerät und QR-Code-Generator kostenlos'>Deutsch</a> -
        <a href="https://qrcodegenerateur.rezz.eu" title='QR code, lecteur en ligne et générateur de QR code gratuit' alt='QR code, lecteur en ligne et générateur de QR code gratuit'>Français</a> - 
        <a href="https://qrkod.rezz.eu" title='QR kód, online čtečka a generátor QR kódu zdarma' alt='QR kód, online čtečka a generátor QR kódu zdarma'>Česky</a> -
        <a href="https://qrcode.rezz.eu" title='QR kód, online čítačka a generátor QR kódu zadarmo' alt='QR kód, online čítačka a generátor QR kódu zadarmo'>Slovensky</a> 
      </center>
    </div>
  );
}

export default withNamespaces()(App);